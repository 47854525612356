import React from "react";
import scrollToElement from "../scrollIntoView";

export default function Menu({handleClose}) {
    const handleClick = e => {
        e.preventDefault();
        const elementId = e.target.href.split('#')[1];
        scrollToElement(elementId)

        handleClose && handleClose()
    }

    return (<ul className="menu-list">
        <li className="menu-item"><a href={'#products'} onClick={handleClick}>Дрони та РЕБ</a></li>
        <li className="menu-item"><a href={'#about-us'} onClick={handleClick}>Про Проєкт</a></li>
        <li className="menu-item"><a href={'#cooperation'} onClick={handleClick}>Співпраця</a></li>
        <li className="menu-item"><a href={'#fundraisers'} onClick={handleClick}>Активні Збори</a></li>
        <li className="menu-item"><a href={'#deliveries'} onClick={handleClick}>Звіти</a></li>
        <li className="menu-item"><a href={'#faq'} onClick={handleClick}>FAQ</a></li>
    </ul>)
}
