import React from "react";
import ContactForm from "../components/ContactForm";

export default function ContactUs() {

    return (<div id={'contact-us'} className={'px-1 lg:px-2 py-4 bg-texture-light text-dark'}>
        <div className={'xl:flex xl:gap-4 xl:justify-between'}>
            <div className={'lg:flex lg:flex-row lg:justify-between xl:flex-col xl:justify-start lg:gap-6 xl:gap-1'}>
                <h2 className={'text-xl lg:w-1/2 xl:w-full uppercase tactic-sans mb-1 lg:mb-0'}>Зв’язатися</h2>

                <p className={'text-base lg:w-1/2 xl:w-full lg:text-right xl:text-left'}>
                    Для співпраці напишіть нам&nbsp;<br className={'hidden lg:flex'}/>
                    запит через форму</p>
            </div>

            <div className={'py-2 xl:p-0 xl:w-2/5'}>
                <ContactForm/>
            </div>
        </div>
    </div>)
}
