import {Link} from "../components";
import {Fragment} from "react";
import scrollToElement from "../scrollIntoView";

const items = [
    {
        question: 'Хто фінансує проєкт Flying Head.',
        answer: 'Проєкт фінансується донейтами громадян та бізнесів через БО «БФ «МАРКУС ФУНДЕЙШН».\n' +
            'Flying Head (Летюча голова) – ветеранський проєкт, ядро якого складають учасники бойових дій, колишні службовці 47 окремої механізованої бригади ЗСУ.\n' +
            'Проєктом опікується благодійний фонд Markus Foundation. Фонд забезпечує всі необхідні процеси – пошук фінансування, закупівлі, організацію взаємодії з військовими частинами Сил оборони тощо.'
    }, {
        question: 'Як бригада чи команда може отримати дрони від Flying Head.',
        answer: <Fragment>Ми передаємо дрони військовим частинам офіційно за актом прийому-передачі. Будь ласка, заповніть <Link onClick={() => scrollToElement("contact-us")}>форму</Link></Fragment>
    }, {
        question: 'Як приєднатися до проєкту Flying Head.',
        answer: 'Наразі у проєкті немає відкритих вакансій. Проте з розширенням виробництва ми плануємо збільшувати команду ветеранів. Ви можете залишити свої контактні дані та короткий опис вашого досвіду за посиланням. Ми зв\'яжемося з вами, коли виникне потреба. Наш проєкт створений ветеранами для ветеранів. Якщо ви не ветеран, але маєте корисну експертизу, залиште свої контакти та опишіть, як саме можете допомогти у нашій роботі. Ми зв\'яжемося з вами, якщо виникне така необхідність.'
    },
];

const QA = ({question, answer}) => <div>
    <p className={'text-base text-primary mb-1'}>{question}</p>
    <p className={'text-sm text-dark'}>{answer}</p>
</div>

export default function Faq() {
    return (<div id={'faq'}
                 className={'px-1 py-4 lg:px-2 lg:py-6 bg-texture-light text-dark'}>
        <div className={'lg:flex lg:flex-row'}>
            <div className={'lg:w-1/3'}>
                <h2 className={'text-xl uppercase tactic-sans mb-1'}>FAQ</h2>
                <p className={'text-base text-primary'}>Питання та відповіді</p>
            </div>

            <div className={'mt-3 lg:mt-0 flex flex-col gap-2 lg:w-2/3 lg:pr-6'}>
                {items.map((item, index) => <QA {...item} key={index}/>)}
            </div>
        </div>
    </div>)
}
