import {FlightStack, Battery, Camera} from '../icons'

export default function ProductProperties() {
    return (<div id={'product-properties'}
                 className={'flex flex-col lg:flex-row gap-2 px-1 lg:px-2 py-4 lg:py-3'}>
        <h2 className={'text-xl tactic-sans col-1/3 word-wrap lg:[word-spacing:50vw]'}>TTX FPV</h2>
        <div className={'block-content col-2/3'}>
            <p className={'text-base'}>Для збірки дронів ми використовуємо деталі від перевірених виробників, гарантуємо
                якісну пайку та проводимо тестові обльоти</p>

            <div className={'properties mt-2 lg:mt-3'}>
                <div className={'property lg:order-1'}>
                    <label>Відеопередавач</label>
                    <p>2.5W 5.8 GHz</p>
                </div>

                <div className={'property lg:order-3 xl:order-2'}>
                    <label>Антена</label>
                    <p>5.8G (права) 160 мм</p>
                </div>

                <div className={'property lg:order-5 xl:order-3'}>
                    <label>Ресівер (приймач)</label>
                    <p>915MHz</p>
                </div>

                <div className={'property lg:order-2 xl:order-4'}>
                    <label><span><FlightStack/></span>Політний стек</label>
                    <p>50A 30x30</p>
                </div>

                <div className={'property lg:order-4 xl:order-5'}>
                    <label><span><Battery/></span>Акумулятор</label>
                    <p>6S2P 8Ah 40T</p>
                </div>

                <div className={'property lg:order-6'}>
                    <label><span><Camera/></span>Камера</label>
                    <p>Micro 1200TVL</p>
                </div>
            </div>
        </div>
    </div>)
}
