import {ReactComponent as LogoLight} from "../assets/img/logo-light.svg";
import {ReactComponent as LogoDark} from "../assets/img/logo-dark.svg";
import React from "react";
import Link from "./Link";


export default function Logo({theme = 'dark', ...props}) {
    return <Link className={'logo'} href={'/'}>
        {theme === 'light' ? <LogoLight/> : <LogoDark/>}
    </Link>;
}
