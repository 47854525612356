import React from 'react';

const IconButton = ({ className, children, ...props }) => {
    if (props.href) {
        return <a className="icon-button" {...props}>{children}</a>
    }

    return (
        <button className="icon-button" {...props}>
            {children}
        </button>
    );
};

export default IconButton;
