import './App.scss';
import {Header} from "./parts/Header";
import React, {useState} from "react";
import Hero from "./parts/Hero";
import MobileMenu from "./parts/MobileMenu";
import ProductProperties from "./parts/ProductProperties";
import {Products} from "./parts/Products";
import EW from "./parts/EW";
import AboutUs from "./parts/AboutUs";
import Cooperation from "./parts/Cooperation";
import ContactUs from "./parts/ContactUs";
import Footer from "./parts/Footer";
import Deliveries from "./parts/Deliveries";
import AboutFlyingHead from "./parts/AboutFlyingHead";
import Reviews from "./parts/Reviews";
import Faq from "./parts/Faq";
import useIsMobile from "./isMobile";
import Fundraisers from "./parts/Fundraisers";
import Videos from "./parts/Videos";
import {FormProvider} from "./FormContext";

function App() {
    const isMobile = useIsMobile();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    return (
        <div id={'home'}>
            <FormProvider>
                {isMobile && <MobileMenu open={isMenuOpen} toggleMenu={toggleMenu}/>}
                <Header/>
                <Hero toggleMenu={toggleMenu}/>

                <ProductProperties/>
                <Products/>
                <EW/>
                <AboutUs/>
                <Cooperation/>
                <ContactUs/>
                <Videos/>
                <Fundraisers/>
                <Deliveries/>
                <AboutFlyingHead/>
                <Reviews/>
                <Faq/>

                <Footer/>
            </FormProvider>
        </div>
    );
}

export default App;
