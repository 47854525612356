import {Engine, Propeller} from '../icons'
import {Slider} from "../components/Slider";
import {Fragment} from "react";
import {useFormContext} from "../FormContext";
import scrollToElement from "../scrollIntoView";
import {Button} from "../components";

const items = [
    {
        name: 'Летюча голова',
        code: 'FPV7',
        size: '7”',
        engine: '2807 - 1300 KV',
        propellers: '7 x 4 x 3 см',
        images: [
            '/wp-content/uploads/7-1.png',
            '/wp-content/uploads/7-2.png',
            '/wp-content/uploads/7-3.png',
            '/wp-content/uploads/7-4.png',
            '/wp-content/uploads/7-5.png',
            '/wp-content/uploads/7-6.png'
        ]
    },
    {
        name: 'Летюча голова',
        size: '10”',
        code: 'FPV10',
        engine: '3115 - 900 KV',
        propellers: '10 x 5 x 3 см',
        images: [
            '/wp-content/uploads/10-1.png',
            '/wp-content/uploads/10-2.png',
            '/wp-content/uploads/10-3.png',
            '/wp-content/uploads/10-4.png',
            '/wp-content/uploads/10-5.png',
        ]
    },
];

export function Products() {
    const {updateFormData} = useFormContext();
    const handleClick = ({title, text}) => {
        scrollToElement('contact-us')

        updateFormData({title, inquiry: text})
    }

    return (<div id={'products'} className={'lg:px-2 mb-4'}>
        {items.map((item, index) => <div key={index}
                                         className={`product-card lg:flex lg:gap-2 ${index % 2 === 1 ? 'lg:flex-row-reverse' : 'lg:flex-row'}`}>
                <div className={'product-slider col-2/3'}>
                    <Slider spaceBetween={20}
                            zoom={true}
                            slides={item.images.map((img, i) => <Fragment key={i}>
                                <img className={'slide-img'} alt={`${item.name} ${i + 1}`} src={img}/>
                            </Fragment>)}/>
                </div>
                <div className={'product-info col-1/3'}>
                    <div className={'product-info-head'}>
                        <h3 className={'text-base'}>{item.name}</h3>
                        <span className="size tactic-sans">{item.size}</span>
                    </div>
                    <div className={'properties'}>
                        <div className={'property'}>
                            <label><span><Engine/></span>Мотор</label>
                            <p>{item.engine}</p>
                        </div>

                        <div className={'property'}>
                            <label><span><Propeller/></span>Пропелери 4 штуки</label>
                            <p>{item.propellers}</p>
                        </div>
                    </div>

                    <Button onClick={() => handleClick({title: item.code, text: item.code})}>ЗРОБИТИ ЗАПИТ
                    </Button>
                </div>
            </div>
        )}
    </div>);
}
