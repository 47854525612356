import React, {useRef, useState} from 'react';
import {PlayButton} from "../icons";

const VideoPlayer = ({ videoSrc, poster }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }

        setIsPlaying(!isPlaying)
    };

    return (
        <div className="video-player">
            <video
                ref={videoRef}
                src={videoSrc}
                poster={poster}
                muted
                className="video"
                onClick={!isPlaying ? togglePlay : null} // Allow toggling video by clicking the video itself
                controls={isPlaying} // Hides the default controls
            ></video>

            {!isPlaying && (
                <div className="play-btn-container">
                    <PlayButton onClick={togglePlay} />
                </div>
            )}
        </div>
    );
};

export default VideoPlayer;
