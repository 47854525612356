import {ReactComponent as FlyingHead} from '../assets/img/flying-head.svg';
import {ReactComponent as MarkusFoundation} from '../assets/img/markus-foundation.svg';

const veterans = [
    {
        name: '«Циган»',
        img: '/wp-content/uploads/person-zygan-photo.jpg'
    }, {
        name: '«Чотирнадцятий»',
        img: '/wp-content/uploads/person-14-photo.jpg'
    },
];

export default function AboutUs() {
    return (<div id={'about-us'} className={'lg:mb-6'}>
        <div className="flex flex-col gap-2 pl-1 lg:p-0 lg:mb-3">
            <div className={'placeholder'}>
                <FlyingHead id={'flying-head-icon'}/>
            </div>

            <h2 className={'text-xl tactic-sans uppercase'}>Про нас</h2>

            <p className={'text-base pr-1'}>Flying Head (Летюча голова) – проєкт ветеранів російсько-української
                війни.</p>
        </div>

        <div id={'veterans'}>
            {veterans.map((veteran, index) =>
                <div key={index} className={'veteran-card'}>
                    <img src={veteran.img} alt={veteran.name} className={'w-full'}/>
                    <p className={'text-sm text-center pt-1'}>{veteran.name}</p>
                </div>)}
        </div>

        <div className={'flex flex-col px-1 lg:px-0 mb-3 gap-2 lg:gap-3'}>
            <p className={'text-sm'}>«Циган» та «Чотирнадцятий» служили в ТРО та 47 окремій механізованій бригаді
                (47 ОМБр). Обороняли Київщину, брали участь у операціях на Харківщини, в Донецькій області та на
                Запоріжжі.</p>
            <p className={'text-sm'}>Розробляють дрони, а також засоби радіоелектронної боротьби (РЕБ) в тісній
                співпраці з підрозділами, щоб підсилювати та захищати українських військових.</p>
        </div>

        <div className={'flex flex-col gap-1 p-1 lg:p-0 pb-4 relative'}>
            <MarkusFoundation id={'markus-foundation-logo'}/>

            <p className={'text-sm opacity-70'}>Проєкт реалізований за підтримки благодійного фонду Markus Foundation,
                який з 2022 року займається підсиленням Сил Оборони амуніцією, автівками, технікою та обладнанням.</p>
        </div>
    </div>);
}
