import React, {useState} from 'react';
import {Copy} from "../icons";

const ClickToCopy = ({children, content}) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(content).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Reset the "Copied!" tooltip after 2 seconds
        });
    };

    return (
        <div className="click-to-copy">
            <span className="copy-text">{children}</span>
            <button className="copy-button" onClick={handleCopy}>
                <Copy/>
            </button>

            {isCopied && <div className="tooltip">Скопійовано!</div>}
        </div>
    );
};

export default ClickToCopy;
