import React, {createContext, useState, useContext} from 'react';

const FormContext = createContext();

export const useFormContext = () => {
    return useContext(FormContext);
};

export const FormProvider = ({children}) => {
    const [formData, setFormData] = useState({
        title: '',
        name: '',
        phone: '',
        email: '',
        inquiry: ''
    });

    // Update the form data
    const updateFormData = (newData) => {
        setFormData({...formData, ...newData});
    };

    return (
        <FormContext.Provider value={{formData, updateFormData}}>
            {children}
        </FormContext.Provider>
    );
};
