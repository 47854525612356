import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';

import {useState} from "react";
import {ArrowLeft, ArrowRight} from "../icons";

import {Zoom, Navigation} from 'swiper/modules';

export function Slider({slides, zoom, pagination = true, slidesPerView = 1, className, ...props}) {
    const [currentSlide, setCurrentSlide] = useState(1);
    const totalSlides = slides.length;

    return (
        <div className={`swiper-container ${className || ''}`}>
            <Swiper
                zoom={zoom}
                {...props}
                slidesPerView={slidesPerView}
                modules={[Navigation, ...(zoom ? [Zoom] : [])]}
                onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex + 1)}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
            >
                {slides.map((item, index) => (
                    <SwiperSlide key={index}>
                        {zoom ? <div className="swiper-zoom-container">
                            {item}
                        </div> : item}
                    </SwiperSlide>
                ))}

                <div className="swiper-button-prev swiper-arrow">
                    <ArrowLeft/>
                </div>
                <div className="swiper-button-next swiper-arrow">
                    <ArrowRight/>
                </div>
            </Swiper>

            {pagination && <div className="swiper-pagination">
                {currentSlide} <span className={'separator'}>/</span> <span
                className={'total-pages'}>{totalSlides}</span>
            </div>}
        </div>
    );
}
