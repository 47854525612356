import React, {useState} from 'react';
import InputGroup from "./InputGroup";
import {Button} from "./index";
import {useFormContext} from "../FormContext";

const apiUrl = process.env.REACT_APP_API_URL;

const ContactForm = () => {
    const {formData, updateFormData} = useFormContext();

    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const {name, value} = e.target;
        updateFormData({...formData, [name]: value});
    };

    const validateForm = () => {
        let formErrors = {};
        setSuccessMessage('');
        let isValid = true;

        if (!formData.name.trim()) {
            formErrors.name = "Ім'я є обов'язковим.";
            isValid = false;
        }

        if (!formData.phone.trim()) {
            formErrors.phone = "Телефон є обов'язковим.";
            isValid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim()) {
            formErrors.email = "Пошта є обов'язковою.";
            isValid = false;
        } else if (!emailRegex.test(formData.email)) {
            formErrors.email = "Невірний формат електронної пошти.";
            isValid = false;
        }

        if (!formData.inquiry.trim()) {
            formErrors.inquiry = "Текст запиту є обов'язковим.";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const response = await fetch(apiUrl + '/contact-form/v1/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        const result = await response.json();
        if (result.success) {
            setSuccessMessage(result.data);
            updateFormData({
                name: '',
                phone: '',
                email: '',
                inquiry: ''
            });
        } else {
            setErrors({...errors, general: result.data})
        }
    };

    return (
        <form className="contact-form flex flex-col" onSubmit={handleSubmit}>
            <div className={'grid lg:grid-cols-3 lg:gap-2 gap-0.5'}>
                <div>
                    <InputGroup
                        label="Ім'я"
                        name="name"
                        value={formData.name}
                        error={errors.name}
                        onChange={handleChange}
                        placeholder="Вкажіть ім'я"
                    />
                </div>
                <div>
                    <InputGroup
                        label="Телефон"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        error={errors.phone}
                        placeholder="Вкажіть телефон"
                    />
                </div>
                <div>
                    <InputGroup
                        label="Пошта"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        error={errors.email}
                        placeholder="Вкажіть електронну адресу"
                    />
                </div>
            </div>

            <div className={'my-1.5'}>
                <InputGroup
                    name="inquiry"
                    value={formData.inquiry}
                    onChange={handleChange}
                    error={errors.inquiry}
                    placeholder="Розкрийте деталі вашого запиту"
                    isTextArea={true}
                    rows={5}
                />
            </div>

            <Button type={'submit'}>
                Надіслати
            </Button>

            {(successMessage || errors?.general) && <div className={'flex justify-center p-1'}>
                {successMessage && <p className="text-green-600">{successMessage}</p>}
                {errors?.general && <p className="text-primary">{errors?.general}</p>}
            </div>}
        </form>
    );
};

export default ContactForm;
