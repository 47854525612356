import React from 'react';

const Button = ({children, ...props}) => {
    const className = 'btn btn-primary tactic-sans uppercase block w-full text-center'

    if (props.href) {
        return <a className={className} {...props}>{children}</a>
    }

    return (
        <button className={className} {...props}>
            {children}
        </button>
    );
};

export default Button;
