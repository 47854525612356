import {ReactComponent as EWIcon} from '../assets/img/ew.svg';
import {useFormContext} from "../FormContext";
import scrollToElement from "../scrollIntoView";
import {Button} from "../components";

export default function EW() {
    const {updateFormData} = useFormContext();
    const handleClick = () => {

        scrollToElement('contact-us')

        updateFormData({title: 'РЕБ', inquiry: 'РЕБ'})
    }

    return (<div id={'electronic-warfare'} className={'flex flex-col lg:flex-row gap-1 lg:gap-2 pb-6 lg:px-2'}>
        <div className={'bg-neutral-100 col-2/3 flex p-1 lg:p-2 justify-center items-center'}>
            <EWIcon id={'ew-icon'}/>
        </div>

        <div className={'p-1 lg:p-0 flex flex-col gap-1 col-1/3'}>
            <h2 className={'tactic-sans text-xl'}>РЕБ</h2>
            <p className={'text-base'}>
                Виготовляємо індивідуально відповідно до вимог та завдань окремого підрозділу.
            </p>
            <Button onClick={handleClick}>ЗРОБИТИ ЗАПИТ</Button>
        </div>
    </div>)
}
