import {Burger} from '../icons';
import React from "react";
import Logo from "../components/Logo";
import useIsMobile from "../isMobile";
import Menu from "../components/Menu";

export default function Hero({toggleMenu}) {
    const isMobile = useIsMobile();
    return (
        <div id={'hero'}>
            <div className="menu-header">
                {isMobile && <button className="btn btn-secondary toggle-btn" onClick={toggleMenu}>
                    <Burger/>
                </button>}

                {!isMobile && <Menu />}
            </div>

            <div id={'hero-content'}>
                <Logo />
                <h1 className={'text-white italic uppercase tactic-sans'}>Дрони та засоби<br/>РЕБ від військових</h1>
            </div>
        </div>
    )
}
