import React from "react";
import {Slider} from "../components/Slider";
import VideoPlayer from "../components/VideoPlayer";
import useIsMobile from "../isMobile";

const videos = [
    '/wp-content/uploads/IMG_3658.MOV',
    '/wp-content/uploads/IMG_3662.MOV'
];

export default function Videos() {
    const isMobile = useIsMobile();

    if (isMobile && !videos.includes('/wp-content/uploads/IMG_3657.MOV')) {
        videos.unshift('/wp-content/uploads/IMG_3657.MOV')
    }

    return (<div id={'results'} className={'text-white py-4 lg:py-6 px-1 lg:px-2 bg-texture-dark'}>
        <div className={'lg:flex lg:flex-row lg:justify-between lg:gap-6 mb-3'}>
            <h2 className={'text-xl lg:w-1/2 uppercase tactic-sans mb-1 lg:mb-0'}>Результати</h2>
            <p className={'text-base text-primary lg:w-1/2 lg:text-right'}>Робота підрозділів<br
                className={'hidden lg:inline'}/>&nbsp;нашими дронами</p>
        </div>

        <div className={'lg:flex lg:flex-row lg:items-stretch lg:gap-x-2'}>
            {!isMobile && <div className={'lg:w-3/5'}>
                <VideoPlayer videoSrc={'/wp-content/uploads/IMG_3657.MOV'}/>
            </div>}
            <div id={'results-slider'} className={'lg:w-2/5 h-full'}>
                <Slider className={'theme-white'}
                        zoom={false}
                        slides={videos.map(videoSrc => <VideoPlayer videoSrc={videoSrc}/>)}/>
            </div>
        </div>
    </div>)
}
