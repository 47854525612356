import {Checklist, Handshake, Labor, Muscle} from "../icons";

export default function Cooperation() {
    return (<div id={'cooperation'} className={'px-1 lg:px-2 py-4 text-white bg-texture-dark'}>
        <h2 className={'tactic-sans text-xl uppercase'}>Співпраця з нами – це:</h2>

        <div id={'cooperation-benefits'}
             className={'flex flex-col gap-2 lg:gap-y-3 mt-2 lg:mt-3 pr-1 lg:pr-0 lg:grid lg:grid-cols-2'}>
            <div className="benefit lg:flex lg:flex-row lg:gap-2 lg:items-center">
                <Muscle className={'text-primary mb-1.5 lg:m-0 lg:w-[77px] shrink-0'}/>
                <div>
                    <h3 className={'text-sm tactic-sans mb-0.5'}>Підсилення та захист війська</h3>
                    <p className={'text-sm'}>ефективними дронами та засобами РЕБ</p>
                </div>
            </div>
            <div className="benefit lg:flex lg:flex-row lg:gap-2 lg:items-center">
                <Checklist className={'text-primary mb-1.5 lg:m-0 lg:w-[77px] shrink-0'}/>
                <div>
                    <h3 className={'text-sm tactic-sans mb-0.5'}>Закриття потреб підрозділів</h3>
                    <p className={'text-sm'}>у технологічних виробах, які підходять саме для їхніх завдань та потреб</p>
                </div>
            </div>
            <div className="benefit lg:flex lg:flex-row lg:gap-2 lg:items-center">
                <Labor className={'text-primary mb-1.5 lg:m-0 lg:w-[77px] shrink-0'}/>
                <div>
                    <h3 className={'text-sm tactic-sans mb-0.5'}>Працевлаштування ветеранів</h3>
                    <p className={'text-sm'}>При збільшенні потужностей ми зможемо запросити до нас у команду більше фахівців-ветеранів</p>
                </div>
            </div>
            <div className="benefit lg:flex lg:flex-row lg:gap-2 lg:items-center">
                <Handshake className={'text-primary mb-1.5 lg:m-0 lg:w-[77px] shrink-0'}/>
                <div>
                    <h3 className={'text-sm tactic-sans mb-0.5'}>Допомога в інтеграції ветеранів</h3>
                    <p className={'text-sm'}>в цивільне суспільство та одночасно робота над підсиленням та захистом українського війська</p>
                </div>
            </div>
        </div>
    </div>);
}
