import {Horror, Precision, Running} from "../icons";

const items = [
    {
        icon: <Horror/>,
        title: 'Страх і паніка',
        text: 'Разом з військовими наші дрони наганяють страх на ворогів.'
    },
    {
        icon: <Running/>,
        title: 'Неминучість',
        text: 'Летючу Голову неможливо зупинити або втекти від неї. Так само наші дрони призначені для того, щоб бути незворотною силою на полі бою, яка не залишає ворогу шансів.'
    },
    {
        icon: <Precision/>,
        title: 'Точність',
        text: 'З нашими дронам військові діють рішуче, точно й ефективно.'
    },
];

const AboutFlyingHeadBlock = ({icon, title, text}) => {
    return (<div className="bg-texture-light px-1 pt-2 pb-1.5 lg:pt-4 lg:pb-2 text-center lg:w-1/3">
        <div className={'mb-1 lg:mb-3 text-primary flex justify-center'}>
            <div className={'w-[128px] h-[128px]'}>
                {icon}
            </div>
        </div>

        <h5 className={'mb-0.5 text-primary text-base'}>{title}</h5>
        <p className={'text-dark text-sm px-1'}>{text}</p>
    </div>)
}

export default function AboutFlyingHead() {
    return (<div id={'about-flying-head'} className={'py-2 lg:py-6'}>
            <div className={'about-content'}>
                <div className="about-header p-1 lg:p-0 flex items-center mb-2 lg:relative">
                    <h2 className={'tactic-sans uppercase text-xl'}>Про<br/>
                        Летючу<br/>
                        голову</h2>

                    <p className={'text-primary text-base'} id={'project-name-history'}>Історія<br
                        className={'lg:hidden'}/>&nbsp;назви&nbsp;<br className={'hidden lg:inline'}/>проєкту
                    </p>
                </div>

                <div className={'p-1 lg:p-0 lg:pr-4 text-xs'}>
                    Flying Head або Летюча Голова. На цю назву проєкту побратимів-співзасновників Flying Head надихнула
                    давня
                    легенда про ірокезів.
                    <br/>
                    <br/>
                    Летюча голова – це міфічний монстр, відокремлена від тіла голова з очима, які палають, та довгим
                    розпатланим
                    волоссям.
                    <br/>
                    <br/>
                    Голова літає, переслідує і наганяє страх.
                </div>

                <div className={'p-1 lg:p-0'}>
                    <blockquote className={'mt-2 text-base text-dark px-1.5 lg:px-0 block'}>
                        Наші FPV-дрони, як Летюча Голова, разом з військовими діють швидко та точно, наганяючи жах і
                        хаос на
                        ворога.
                    </blockquote>
                </div>
            </div>

            <div className={'px-1'}>
                <div className={'about-items mt-3 flex flex-col lg:flex-row gap-1 lg:gap-1.5'}>
                    {items.map((item, index) => <AboutFlyingHeadBlock {...item} key={index}/>)}
                </div>
            </div>
        </div>
    )
}
