import React from 'react';

const Link = ({children, className, ...props}) => {
    return (
        <a {...props} className={className || "link"}>
            {children}
        </a>
    );
};

export default Link;
