import React from 'react';

const TextAreaField = ({name, error, value, onChange, placeholder = '', rows = 4}) => {
    return (
        <textarea
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            rows={rows}
            className={`form-control ${error ? 'has-error' : ''}`}
        />
    );
};

export default TextAreaField;
