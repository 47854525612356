import React from 'react';

const InputField = ({name, error, type = 'text', value, onChange, placeholder = ''}) => {
    return (
        <input
            type={type}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={`form-control ${error ? 'has-error' : ''}`}
        />
    );
};

export default InputField;
