import React from 'react';
import InputField from './InputField';
import TextAreaField from './TextareaField';

const InputGroup = ({label, name, value, error, onChange, placeholder = '', type = 'text', isTextArea = false, rows = 4}) => {
    return (
        <div className="input-group">
            {label && <label htmlFor={name} className={'block text-xs mb-0.5'}>{label}</label>}
            {isTextArea ? (
                <TextAreaField
                    label={label}
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    error={error}
                    rows={rows}
                />
            ) : (
                <InputField
                    label={label}
                    name={name}
                    type={type}
                    error={error}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            )}

            {error && <p className="text-primary">{error}</p>}
        </div>
    );
};

export default InputGroup;
