import React from "react";
import "../assets/scss/MobileMenu.scss";
import {Times} from '../icons';
import Logo from "../components/Logo";
import Menu from "../components/Menu";

const MobileMenu = ({open, toggleMenu}) => {
    return (
        <div className={`bg-texture-dark mobile-menu ${open ? "open" : ""}`}>
            <div className="menu-header">
                <Logo theme={'light'} />
                <button className="btn btn-primary toggle-btn" onClick={toggleMenu}>
                    <Times/>
                </button>
            </div>

            <Menu handleClose={toggleMenu} />
        </div>
    );
};

export default MobileMenu;
