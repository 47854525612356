import React from 'react';
import ProgressBar from './ProgressBar';

export const formatCurrency = (amount) => {
    return amount.toLocaleString('uk-UA', {minimumFractionDigits: 0});
};

const DonationProgress = ({collected, total}) => {
    const collectedAmount = Math.floor(collected / 100); // Remove decimals
    const totalAmount = Math.floor(total / 100); // Remove decimals
    const remainingAmount = totalAmount - collectedAmount;
    const remainingPercentage = Math.round((remainingAmount / totalAmount) * 100);

    return (
        <div className="donation-progress">
            {/* Progress bar */}
            <ProgressBar progress={(collectedAmount / totalAmount) * 100} />

            {/* Collected and Remaining Information */}
            <div className="mt-1 flex justify-between">
                <div>
                    <span className={'text-sm'}>Зібрано</span>
                    <div className={'mt-[4px]'}>
                        <span className="tactic-sans text-sm">{formatCurrency(collectedAmount)}&nbsp;</span>
                        <small className={'text-primary text-xxs'}>₴</small>
                    </div>
                </div>
                <div className="text-right">
                    <span className={'text-sm'}>Залишилось <strong>{remainingPercentage}%</strong></span>
                    <div className={'mt-[4px]'}>
                        <span className="tactic-sans text-sm">{formatCurrency(remainingAmount)}&nbsp;</span>
                        <small className={'text-primary text-xxs'}>₴</small>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DonationProgress;
