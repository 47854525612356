import {formatCurrency} from "../components/DonationProgress";
import ClickToCopy from "../components/ClickToCopy";
import {Button, Link} from "../components";
import React from "react";

export default function Fundraisers() {
    return (<div id={'fundraisers'} className={'px-1 py-4 lg:px-2 lg:py-6'}>
        <div className={'lg:flex lg:flex-row lg:justify-between lg:gap-6 mb-3'}>
            <h2 className={'text-xl lg:w-9/12 uppercase tactic-sans mb-1 lg:mb-0'}>Активні збори</h2>
            <p className={'text-base text-primary lg:w-3/12 lg:text-right'}>Ставай частиною&nbsp;<br
                className={'hidden lg:inline'}/>великих справ</p>
        </div>

        <div className={'fundraiser lg:flex lg:items-center lg:justify-center lg:gap-2'}>
            <div className={'lg:max-w-3/5'}>
                <img alt={'Збираємо 3,7 млн грн на FPV-дрони'}
                     style={{maxHeight: '60vh'}}
                     src={'/wp-content/uploads/fundraiser.jpg'} />
            </div>

            <div className={'lg:w-2/5 lg:px-2'}>
                <h4 className={'text-base mt-1.5'}>Збираємо 3,7 млн грн на FPV-дрони, наземні станції та засоби РЕБ
                    для 47-ї окремої механізованої бригади</h4>

                <div className={'my-1.5'}>
                    <div>
                        <span className={'text-sm'}>Сума</span>
                        <div className={'mt-[5px]'}>
                            <span className="tactic-sans text-sm">{formatCurrency(3700000)}&nbsp;</span>
                            <small className={'text-primary text-xxs'}>₴</small>
                        </div>
                    </div>
                </div>

                <div className={'mb-1.5 flex justify-between tactic-sans items-center'}>
                    <span>PayPal:</span>
                    <ClickToCopy content={'vvs.vasiliev@gmail.com'}>vvs.vasiliev@gmail.com</ClickToCopy>
                </div>

                <Button target={'_blank'} href={'https://send.monobank.ua/jar/6eV2c6enbF'}>Задонатити</Button>

                <div className={'flex justify-center mt-1.5'}>
                    <Link target={'_blank'}
                          className={'text-base underline text-default'}
                          href={'https://markusfoundation.com/rekvizyty-blahodiynoho-fondu-markus-foundation'}>Усі
                        реквізити</Link>
                </div>
            </div>
        </div>
    </div>)
}
