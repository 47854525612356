import {IconButton, Link} from "../components";
import Logo from "../components/Logo";
import {Facebook, Instagram, Telegram, TikTok} from "../icons";
import useIsMobile from "../isMobile";

const socials = [
    {icon: <Facebook/>, href: 'https://www.facebook.com/profile.php?id=61564459086659&locale=uk_UA'},
    {icon: <Instagram/>, href: 'https://www.instagram.com/flyinghead.drones'},
    {icon: <Telegram/>, href: 'https://t.me/flyinghead_drones'},
    {icon: <TikTok />, href: 'https://www.tiktok.com/@flyinghead_drones'}
];

export default function Footer() {
    const isMobile = useIsMobile();
    const contactUsLink = <Link href="mailto:info@flyinghead.tech" className={'text-xs'}>Написати нам</Link>;
    const aboutProject = <p className={'text-xs mb-2.5 lg:mb-0 pr-1'}>Проєкт ветеранів для військових за підтримки&nbsp;<br className={'hidden lg:inline'} />Благодійного
        фонду&nbsp;
        <Link href={'https://markusfoundation.com'} target={'_blank'}>Markus Foundation</Link>
    </p>
    return (<footer className={'px-1 py-4 lg:px-1 xl:px-2 lg:py-3 lg:flex lg:flex-row lg:justify-between lg:gap-3 lg:items-center'}>
        <div className={'flex justify-between items-center mb-1.5 lg:mb-0 lg:gap-2'}>
            <Logo/>

            {isMobile ? contactUsLink : aboutProject}
        </div>

        {isMobile ? aboutProject : contactUsLink}

        <ul className={'flex gap-1'}>
            {socials.map((social, i) => <li key={i}>
                <IconButton href={social.href}
                            target={'_blank'}>
                    {social.icon}
                </IconButton>
            </li>)}
        </ul>
    </footer>)
}
