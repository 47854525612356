import {Slider} from "../components/Slider";
import React from "react";
import useIsMobile from "../isMobile";

const reviewsImages = [
    '/wp-content/uploads/image 92-2.png',
    '/wp-content/uploads/image 92-1.png',
    '/wp-content/uploads/image 92.png',
    '/wp-content/uploads/image 92-2.png',
    '/wp-content/uploads/image 92-1.png',
    '/wp-content/uploads/image 92.png'
];

const Review = ({src}) => {
    return (<div className={'flex justify-center'}>
        <img src={src} alt={'review'} style={{maxHeight: '50vh'}}/>
    </div>)
}

export default function Reviews() {
    const isMobile = useIsMobile();

    return (<div id={'reviews'} className={'px-1 py-4 lg:px-2 xl:py-6 bg-texture-dark'}>
        <div className={'lg:flex lg:flex-row lg:justify-between lg:gap-6 mb-3'}>
            <h2 className={'text-xl text-primary lg:w-9/12 uppercase tactic-sans mb-1 lg:mb-0'}>Відгуки</h2>

            <p className={'text-base text-white lg:w-3/12 lg:text-right'}>від підрозділів про<br
                className={'hidden lg:inline'}/>&nbsp;роботу дронів</p>
        </div>

        <div className={'mt-2'}>
            <Slider pagination={false}
                    slidesPerView={isMobile ? 1 : 3}
                    spaceBetween={20}
                    loop={true}
                    autoHeight={true}
                    className={'arrows-on-top'}
                    slides={reviewsImages.map((src, i) => <Review src={src} key={i}/>)}/>
        </div>
    </div>)
}
